<template>
    <div>
        <div class="cartMainContentWrapper">
            <div class="columns is-desktop" v-if="cartArray.length > 0">
                <div class="column is-8">
                    <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
                    <b-table :data="cartArray"
                             striped
                             detailed
                             :opened-detailed="openedDetails"
                             detail-key="id"
                             :show-detail-icon="false">
                        <template slot-scope="props">
                            <b-table-column field="name" label="Продукция">
                                <a v-if="props.row.url !== ' '" :href="props.row.url">
                                    {{ props.row.name }}
                                </a>
                                <span v-else>
                                    {{ props.row.name }}
                                </span>
                            </b-table-column>
                            <b-table-column field="quantity" label="Количество">
                                {{ props.row.whip }} {{quantityName(props.row.whip)}}
                            </b-table-column>
                            <b-table-column field="price" label="Цена">
                                <div v-if="props.row.cutTimes === 0">
                                    {{ props.row.finalPrice }}  ₽
                                </div>
                                <div v-else>
                                    <b-tooltip
                                        dashed
                                        multilined
                                        animated
                                        type="is-dark"
                                        label="Цена указана без стоимости резки">
                                        <b-icon
                                                type="is-info"
                                                icon="information"
                                                size="is-small">
                                        </b-icon> {{ props.row.total }}  ₽
                                    </b-tooltip>
                                </div>
                            </b-table-column>
                            <b-table-column field="delete" label="">
                                <div class="vue-cart-close" @click="deleteFromCart(props.row.id)">
                                    <b-icon
                                            icon="close-circle"
                                            size="is-small">
                                    </b-icon>
                                </div>
                            </b-table-column>
                        </template>
                        <template slot="detail" slot-scope="props">
                            <article class="media">
                                <figure class="media-left">
                                    <p class="image is-32x32">
                                        <img src="https://rdmetall.ru/image/data/saw-icon.png">
                                    </p>
                                </figure>
                                <div class="media-content">
                                    <div class="content">
                                        <p>Доп. услуги: {{props.row.cutTimes}} реза(ов) по {{props.row.cut}} м. на сумму {{props.row.cutsPrice | rub}} ₽</p>
                                    </div>
                                </div>
                            </article>
                        </template>
                    </b-table>
                </div>
                <div class="column is-4 has-left-separator">
                    <div class="orderPriceInfo">
                        <div class="finalPriceCart">Итого: <strong>{{allProductsTotalPrice | rub}} ₽</strong></div>
                        <div class="quantityCart">{{cartLength}}</div>
                        <div>Общий вес: {{totalKgOrdered}} кг</div>
                    </div>
                    <!--<div class="deliveryInfo">
                        <div class="field">
                            <b-radio v-model="delivery"
                                     native-value="takeaway"
                                     type="is-info">
                                <span class="title is-5">Самовывоз</span>
                            </b-radio>
                        </div>
                        <p>Заберу самостоятельно со склада. Адрес: ул. Шостаковича, д. 8, к.2</p>
                        <div class="field mt20">
                            <b-radio v-model="delivery"
                                     native-value="deliver"
                                     type="is-info">
                                <span class="title is-5">Доставка</span>
                            </b-radio>
                            <p>Доставим в удобное для вас место и время.</p>
                        </div>
                    </div>-->
                    <CartForm class="mt20"></CartForm>
                </div>
            </div>
            <div class="has-text-centered title is-3 has-text-grey-light" v-else><p>В корзине пусто.</p></div>
            <!--<div class="vue-map" v-if="delivery === 'deliver'">
                <iframe style="height: 600px" src="https://yandex.ru/map-widget/v1/?um=constructor%3A12ad18552a8e539ab5ce524d3b91bfe2c00c429567e4aada3257281c9b0238b7&amp;source=constructor" width="100%" height="600" frameborder="0"></iframe>
            </div>-->
        </div>
    </div>
</template>

<script>
    import CartForm from './CartForm.vue'
    export default {
        components: {
            CartForm
        },
        name: "Cart",
        data() {
            return {
                delivery: 'takeaway',
                isLoading: false,
            }
        },
        methods: {
            conjugate(quantity, words) {
                var sec_opt = [2, 3, 4];
                var ten_cond = !(quantity > 10 && quantity < 15);
                var unit_val = (quantity % 10 === 1 && ten_cond)
                    ? words[0]
                    : ((sec_opt.indexOf(quantity % 10) > -1 && ten_cond) ? words[1] : words[2]);
                return unit_val;
            },
            quantityName(qua) {
                return ' ' + this.conjugate(qua, ['штука','штуки','штук']);
            },
            async deleteFromCart(id) {
                this.isLoading = true;
                await new Promise(r => setTimeout(r, 1200));
                this.isLoading = false;
                this.$store.commit('deleteFromCart', id);
            }
        },
        computed: {
            cartArray() {
                return this.$store.getters.cart;
            },
            allProductsTotalPrice() {
                let arr = this.cartArray;
                let result = arr.reduce(function(sum, current) {
                    return sum + Number(current.finalPrice);
                }, 0);
                return Number(result);
            },
            cartLength() {
                return this.cartArray.length + ' ' + this.conjugate(this.cartArray.length, ['товар','товара','товаров'])
            },
            totalKgOrdered() {
                let arr = this.cartArray;
                let result = arr.reduce(function(sum, current) {
                    return sum + Number(current.kgOrdered);
                }, 0);
                return Number(result).toFixed(2);
            },
            openedDetails() {
                let arr = [];
                for (let item of this.cartArray) {
                    if (Number(item.cut) !== 0) {
                        arr.push(item.id);
                    }
                }
                return arr;
            }
        },
        mounted() {
            let list = document.getElementsByClassName("detail-container");
            for (let item of list) {
                item.style.padding = 0;
            }
        }
    }
</script>

<style scoped>
    h2.title {
        margin-top: 10px;
        text-align: center;
    }
    .cartMainContentWrapper {

    }
    .has-left-separator {
        border-left: 4px solid rgba(0, 0, 0, 0.5);
        background-color: rgba(0, 0, 0, 0.05);
    }
    .finalPriceCart {
        font-size: 20px;
    }
    .quantityCart {
        margin-top: 7px;
        margin-bottom: 7px;
    }
    .deliveryInfo {
        margin-top: 25px;
    }
    .mt20 {
        margin-top: 20px;
    }
    .vue-map {
        position: relative;
    }
    .vue-cart-close {
        color: gray;
        transition: all .3s ease-out;
        cursor: pointer;
    }
    .vue-cart-close:hover {
        color: #f44336;
    }
    div.detail-container  {
        padding: 3px !important;
    }
</style>