<template>
    <div v-show="cart.length > 0">
        <a class="cartLinkWrapper" href="https://rdmetall.ru/cart/">
            <div class="cartIconWrapper"><b-icon
                    icon="cart"
                    size="is-large">
            </b-icon></div>
            <div class="cartTextWrapper">
                <span>{{cartLength}}</span>
                <span>{{totalPrice | rub}} ₽</span>
            </div>
        </a>
    </div>
</template>

<script>

    export default {
        name: "CartLink",
        data () {
            return {
                CartIsSwitched: false,
                singleIsSwitched: false
            }
        },

        computed: {
            cart() {
                return this.$store.getters.cart;
            },
            cartLength() {
                return this.cart.length + ' ' + this.conjugate(this.cart.length, ['товар','товара','товаров'])
            },
            totalPrice() {
                let arr = this.cart;
                let result = arr.reduce(function(sum, current) {
                    return sum + Number(current.finalPrice);
                }, 0);
                return Number(result);
            },
        },
        watch: {
          CartIsSwitched(oldCartIsSwitched, newCartIsSwitched) {
              this.$store.commit('switchToCart', newCartIsSwitched);
          }
        },
        methods: {
            conjugate(quantity, words) {
                var sec_opt = [2, 3, 4];
                var ten_cond = !(quantity > 10 && quantity < 15);
                var unit_val = (quantity % 10 === 1 && ten_cond)
                    ? words[0]
                    : ((sec_opt.indexOf(quantity % 10) > -1 && ten_cond) ? words[1] : words[2]);
                return unit_val;
            },
            toggleCart() {
                this.$store.commit('changeComponent', "Cart");
            },
            toggleSingle() {
                this.$store.commit('changeComponent', "SingleProf");
            },
            toggleTable() {
                this.$store.commit('changeComponent', "Table");
            }
        }
    }
</script>

<style scoped>
    .cartLinkWrapper {
        position: fixed;
        top: 100px;
        right: 10px;
        z-index: 1000000;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 15px;
        border-radius: 4px;
        box-shadow: 0 3px 8px 0 rgba(0,0,0,0.2),0 0 0 1px rgba(0,0,0,0.08);
        transition: all 0.2s ease-in-out;
        color: #3A342A;
        background-color: #fff;
    }
    .cartLinkWrapper:hover {
        background-color: rgba(0, 0, 0, 0.04);
        cursor: pointer;
    }
    .blueBg {

    }
    .cartIconWrapper {
        font-size: 40px;
    }
    .cartTextWrapper {
        display: flex;
        align-content: space-between;
        flex-direction: column;
        margin-left: 15px;
    }
</style>