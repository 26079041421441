<template>
    <div class="mainModalContainer has-background-white">
        <h4 class="title is-4">{{name}}</h4>
        <a class="delete is-large" @click="$parent.close()"></a>
        <hr>
        <div class="columns">
            <div class="column is-2-desktop">
                <b-field label="Хлыстов">
                    <b-numberinput controls-position="compact" @input="whipChanged" min="0" max="999" type="is-info"
                                   v-model="whip"></b-numberinput>
                </b-field>
            </div>
            <div class="column is-2-desktop">
                <b-field label="Кол-во, кг">
                    <b-input v-model="kg" name="kg" @input.native="onKgOrMeterChange($event)" @blur="blurKg"
                             @keypress.native="isNumber($event)"></b-input>
                </b-field>
            </div>
            <div class="column is-2-desktop">
                <b-field label="Кол-во, пог.м">
                    <b-input v-model="meter" name="meter" @input.native="onKgOrMeterChange($event)" @blur="blurMeter"
                             @keypress.native="isNumber($event)"></b-input>
                </b-field>
            </div>
            <div class="column is-3-desktop">
                <div class="vue-group">
                    <b-field label="Разрезать по">
                        <b-numberinput @input="onCutChange" controls-position="compact" expanded min="0" max="12" type="is-info"
                                       v-model="cut"></b-numberinput>
                    </b-field>
                    <span class="mspan">м.</span>
                </div>
            </div>
            <div class="column is-3-desktop buyButton">
                <b-button @click="addToCart" type="is-info" expanded>В корзину</b-button>
            </div>
        </div>
        <hr>
        <div class="vue-total">
            <p v-show="total !== 0">{{ totalMessage }} <strong>{{ total | rub }} ₽</strong></p>
            <p v-show="cutsFinalPrice !== 0">&nbsp {{ totalCutsMessage }} <strong>{{ cutsFinalPrice | rub }} ₽</strong></p>
        </div>
        <br>
    </div>
</template>

<script>
    let shortid = require('shortid');
    import {mapGetters} from 'vuex';

    export default {

        data() {
            return {
                kg: '',
                meter: '',
                whip: 0,
                cut: 0,
                cutsFinalPrice: 0,
                cutTimes: 0,
                total: 0,
                showNotification: true,
                cartObj: {}
            }
        },
        computed: {
            ...mapGetters([
                'name',
                'length',
                'cutPrice',
                'price',
                'weight',
                'notificaion',
                'url'
            ]),
            totalMessage() {
                return this.whip + ' ' + this.conjugate(this.whip, ['хлыст','хлыста','хлыстов']) + ' за ';
            },
            totalCutsMessage() {
                return ' и ' + ' ' + this.cutTimes + ' ' + this.conjugate(this.cutTimes, ['рез','реза','резов']) + ' за '
            }
        },
        mounted() {

        },
        methods: {
            // Utility Functions
            isNumber: function (evt) {
                evt = (evt) ? evt : window.event;
                let charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            },
            ROUND(number, increment, offset) {
                return Math.ceil((number - offset) / increment) * increment + offset;
            },
            addToCart(){
                if(this.whip === 0) {
                    this.$buefy.snackbar.open({
                        message: 'Ошибка, вы пытаетесь добавить в корзину 0 кг товара.\n',
                        type: 'is-danger',
                        position: 'is-top',
                        actionText: 'Ок',
                        indefinite: true
                    })
                } else {
                    this.cartObj.name = this.name;
                    this.cartObj.length = this.length;
                    this.cartObj.cutPrice = this.cutPrice;
                    this.cartObj.price = this.price;
                    this.cartObj.url = this.url;
                    this.cartObj.weight = this.weight;
                    this.cartObj.kgOrdered = this.kg;
                    this.cartObj.meter = this.meter;
                    this.cartObj.whip = this.whip;
                    this.cartObj.cut = this.cut;
                    this.cartObj.cutsPrice = this.cutsFinalPrice;
                    this.cartObj.cutTimes = this.cutTimes;
                    this.cartObj.total = this.total;
                    this.cartObj.finalPrice = Number(this.cutsFinalPrice + this.total).toFixed(2);
                    this.cartObj.id = shortid.generate();
                    let cartGood = this.cartObj;
                    this.$store.commit('addToCart', cartGood);
                    this.$buefy.snackbar.open({
                        message: 'Ваш заказ успешно добавлен в корзину!\n',
                        type: 'is-success',
                        position: 'is-top',
                        actionText: 'Хорошо',
                        indefinite: true
                    })
                }
            },
            checkIfNotEmpty() {
                if(Number(this.kg) && Number(this.meter) === 0) {
                    this.$buefy.snackbar.open({
                        message: 'Ошибка, вы пытаетесь добавить в корзину 0 кг товара.\n',
                        type: 'is-error',
                        position: 'is-top',
                        actionText: 'Ок',
                        indefinite: true
                    })
                }
                return 0;
            },
            calcTotal(kg) {
                let kilo = Number(kg);
                let newPrice = Number(this.price / 1000);
                switch (true) {
                    case kilo < 1000:
                        newPrice += 5.00;
                        break;
                    case kilo >= 1000:
                        newPrice += this.price;
                        break;
                    case kilo >= 5000:
                        newPrice -= 1.00;
                        break;
                    default:
                        break;
                }
                let raw = Number(kg) * Number(newPrice);
                this.total = Number(raw.toFixed(2));
            },
            onKgOrMeterChange(event) {
                if (event.target.name === "kg") {
                    if(this.length % this.meter !== 0) {
                        this.warning();
                        this.$store.commit('dontShowNotificationAgain');
                    }
                    let roundedKg = this.ROUND(this.kg, this.weight, 0);
                    this.meter = Number(((roundedKg / this.weight) * this.length).toFixed());
                    this.whip = Number((this.meter / this.length));
                    this.calcTotal(((this.meter / this.length) * this.weight).toFixed(1));
                } else if (event.target.name === "meter") {
                    if(this.meter % 6 !== 0) {
                        this.warning();
                        this.$store.commit('dontShowNotificationAgain');
                    }
                    let roundedMeters = this.ROUND(this.meter, this.length, 0);
                    this.kg = ((roundedMeters / this.length) * this.weight).toFixed(1);
                    this.whip = roundedMeters / this.length;
                    this.calcTotal(this.kg);
                }

            },
            whipChanged() {
                if (this.whip === null) {
                    return false;
                }
                this.meter = this.whip * this.length;
                this.kg = ((this.meter / this.length) * this.weight).toFixed(1);
                this.calcTotal(this.kg);
            },
            onCutChange() {
                let length = this.meter;
                let desiredMeters = this.cut;
                if (desiredMeters === 0 ) {
                    this.cutsFinalPrice = 0;
                } else if (length % desiredMeters !== 0) {
                    let times = Math.floor(Number(length / desiredMeters));
                    this.cutTimes = times;
                    this.cutsFinalPrice = times * this.cutPrice;
                } else if (length % desiredMeters === 0) {
                    let times = Number((length / desiredMeters).toFixed()) - 1;
                    this.cutTimes = times;
                    this.cutsFinalPrice = times * this.cutPrice;
                }
            },
            blurMeter() {
                this.meter = this.ROUND(this.meter, this.length, 0);
            },
            blurKg() {
                this.kg = ((this.meter / this.length) * this.weight).toFixed(1);
            },
            conjugate(quantity, words) {
                var sec_opt = [2, 3, 4];
                var ten_cond = !(quantity > 10 && quantity < 15);
                var unit_val = (quantity % 10 === 1 && ten_cond)
                    ? words[0]
                    : ((sec_opt.indexOf(quantity % 10) > -1 && ten_cond) ? words[1] : words[2]);
                return unit_val;
            },
            warning() {
                if (this.notificaion) {
                    this.$buefy.snackbar.open({
                        message: 'Продаем только целыми хлыстами, поэтому ваш заказ будет округлен до целого хлыста.\n',
                        type: 'is-warning',
                        position: 'is-top',
                        actionText: 'Понятно',
                        indefinite: true
                    })
                }
            }
        }
    }
</script>

<style lang="scss">
    .mainModalContainer {
        padding: 20px 30px;
        border-radius: 3px;

        .delete {
            position: absolute;
            top: 16px;
            right: 30px;
        }

        .vue-group {
            display: flex;
            justify-content: space-around;

            .mspan {
                align-self: center;
                margin-top: 27px;
            }
        }

        .buyButton {
            margin-top: 18px;
            align-self: center;
        }
        .vue-total {
            display: flex;
            justify-content: center;
        }
        h4.title.is-4 {
            margin-bottom: 10px !important;
        }
    }
</style>