<template>
    <div class="mt20" v-if="!hide">
        <div class="columns" v-if="product !== undefined">
            <div class="column is-4">
                <div class="image is-4by3">
                    <img src="https://rdmetall.ru/image/data/1_new_prof_1.png">
                </div>
            </div>
            <div class="column is-4 has-left-separator">
                <div class="title is-4 has-text-centered">Расчитать стоимость</div>
                <div class="flexWrapper">
                    <div>Длина хлыста:</div>
                    <div><strong>{{product.length}} м.</strong></div>
                </div>
                <div class="flexWrapper">
                    <div>Хлыстов</div>
                    <div><b-numberinput
                                                            @input="whipChanged" min="0" max="999"
                                                            type="is-info"
                                                            v-model="whip"></b-numberinput>
                    </div>
                </div>
                <div class="flexWrapper">
                    <div>Килограмм</div>
                    <div><b-input v-model="kg" name="kg" @input.native="onKgOrMeterChange($event)" @blur="blurKg"
                                  @keypress.native="isNumber($event)"></b-input>
                    </div>
                </div>
                <div class="flexWrapper">
                    <div>Метров</div>
                    <div><b-input v-model="meter" name="meter" @input.native="onKgOrMeterChange($event)" @blur="blurMeter"
                                  @keypress.native="isNumber($event)"></b-input>
                    </div>
                </div>
            </div>
            <div class="column is-4 has-left-separator">
                <div class="title is-4 has-text-centered">Цены</div>
                <div class="has-text-centered margin-all">
                    <div>Цена за тонну - <strong>{{product.price | rub}} ₽</strong></div>
                    <div>Цена за хлыст - <strong>{{whipPrice | rub}} ₽</strong></div>
                    <div>Цена за метр - <strong>{{meterPrice}} ₽</strong></div>
                    <div>Цена за рез - <strong>{{product.cut | rub}} ₽</strong></div>
                    <div>Марка стали - <strong>{{product.grade}}</strong></div>
                </div>
            </div>
        </div>
        <div class="title is-4" v-show="total !== 0">Итого</div>
        <div v-if="product !== undefined" v-show="total !== 0" class="ffflex"><div>{{product.name}}, {{ totalMessage }} <strong>{{ total | rub }} ₽</strong></div> <div class="ml40">
            <b-button @click="addToCart" type="is-info">В корзину</b-button></div></div>
    </div>
</template>

<script>
    import helpers from '../../helpers.js'
    import {mapGetters} from 'vuex';
    let shortid = require('shortid');
    export default {
        name: "Single",
        data() {
            return {
                title: "ТРУБА ПРОФИЛЬНАЯ 30Х30Х1,5",
                once: false,
                kg: '',
                meter: '',
                whip: 0,
                cut: 0,
                cutsFinalPrice: 0,
                cutTimes: 0,
                total: 0,
                showNotification: true,
                cartObj: {}
            }
        },
        computed: {
            ...mapGetters([
                'name',
                'length',
                'cutPrice',
                'price',
                'weight',
                'notificaion',
                'url'
            ]),
            productsArray() {
                return this.$store.getters.loadedProducts;
            },
            product() {
                let res = this.productsArray.filter(obj => {
                    return obj.name.toLowerCase() === this.title.toLowerCase()
                });
                return res[0];
            },
            whipPrice() {
                return ((this.product.price / 1000) * this.product.weight).toFixed(0);
            },
            meterPrice() {
                return (this.whipPrice / this.product.length).toFixed(2);
            },
            totalMessage() {
                return this.whip + ' ' + this.conjugate(this.whip, ['хлыст','хлыста','хлыстов']) + ' за ';
            },
            hide() {
                return this.$store.getters.globalHide;
            }
        },
        mixins: [helpers],
        methods: {
            // Utility Functions
            isNumber: function (evt) {
                evt = (evt) ? evt : window.event;
                let charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            },
            ROUND(number, increment, offset) {
                return Math.ceil((number - offset) / increment) * increment + offset;
            },
            addToCart(){
                if(this.whip === 0) {
                    this.$buefy.snackbar.open({
                        message: 'Ошибка, вы пытаетесь добавить в корзину 0 кг товара.\n',
                        type: 'is-danger',
                        position: 'is-top',
                        actionText: 'Ок',
                        indefinite: true
                    })
                } else {
                    this.cartObj.name = this.name;
                    this.cartObj.length = this.length;
                    this.cartObj.cutPrice = this.cutPrice;
                    this.cartObj.price = this.price;
                    this.cartObj.url = this.url;
                    this.cartObj.weight = this.weight;
                    this.cartObj.kgOrdered = this.kg;
                    this.cartObj.meter = this.meter;
                    this.cartObj.whip = this.whip;
                    this.cartObj.cut = this.cut;
                    this.cartObj.cutsPrice = this.cutsFinalPrice;
                    this.cartObj.cutTimes = this.cutTimes;
                    this.cartObj.total = this.total;
                    this.cartObj.finalPrice = Number(this.cutsFinalPrice + this.total).toFixed(2);
                    this.cartObj.id = shortid.generate();
                    let cartGood = this.cartObj;
                    this.$store.commit('addToCart', cartGood);
                    this.$buefy.snackbar.open({
                        message: 'Ваш заказ успешно добавлен в корзину!\n',
                        type: 'is-success',
                        position: 'is-top',
                        actionText: 'Хорошо',
                        indefinite: true
                    })
                }
            },
            calcTotal(kg) {
                let kilo = Number(kg);
                let newPrice = Number(this.price / 1000);
                switch (true) {
                    case kilo < 1000:
                        newPrice += 5.00;
                        break;
                    case kilo >= 1000:
                        newPrice += this.price;
                        break;
                    case kilo >= 5000:
                        newPrice -= 1.00;
                        break;
                    default:
                        break;
                }
                let raw = Number(kg) * Number(newPrice);
                this.total = Number(raw.toFixed(2));
            },
            onKgOrMeterChange(event) {
                if (event.target.name === "kg") {
                    if(this.length % this.meter !== 0) {
                        this.warning();
                        this.$store.commit('dontShowNotificationAgain');
                    }
                    let roundedKg = this.ROUND(this.kg, this.weight, 0);
                    this.meter = Number(((roundedKg / this.weight) * this.length).toFixed());
                    this.whip = Number((this.meter / this.length));
                    this.calcTotal(((this.meter / this.length) * this.weight).toFixed(1));
                } else if (event.target.name === "meter") {
                    if(this.meter % 6 !== 0) {
                        this.warning();
                        this.$store.commit('dontShowNotificationAgain');
                    }
                    let roundedMeters = this.ROUND(this.meter, this.length, 0);
                    this.kg = ((roundedMeters / this.length) * this.weight).toFixed(1);
                    this.whip = roundedMeters / this.length;
                    this.calcTotal(this.kg);
                }

            },
            whipChanged() {
                if (this.whip === null) {
                    return false;
                }
                this.meter = this.whip * this.length;
                this.kg = ((this.meter / this.length) * this.weight).toFixed(1);
                this.calcTotal(this.kg);
            },
            onCutChange() {
                let length = this.meter;
                let desiredMeters = this.cut;
                if (desiredMeters === 0 ) {
                    this.cutsFinalPrice = 0;
                } else if (length % desiredMeters !== 0) {
                    let times = Math.floor(Number(length / desiredMeters));
                    this.cutTimes = times;
                    this.cutsFinalPrice = times * this.cutPrice;
                } else if (length % desiredMeters === 0) {
                    let times = Number((length / desiredMeters).toFixed()) - 1;
                    this.cutTimes = times;
                    this.cutsFinalPrice = times * this.cutPrice;
                }
            },
            blurMeter() {
                this.meter = this.ROUND(this.meter, this.length, 0);
            },
            blurKg() {
                this.kg = ((this.meter / this.length) * this.weight).toFixed(1);
            },
            conjugate(quantity, words) {
                var sec_opt = [2, 3, 4];
                var ten_cond = !(quantity > 10 && quantity < 15);
                var unit_val = (quantity % 10 === 1 && ten_cond)
                    ? words[0]
                    : ((sec_opt.indexOf(quantity % 10) > -1 && ten_cond) ? words[1] : words[2]);
                return unit_val;
            },
            warning() {
                if (this.notificaion) {
                    this.$buefy.snackbar.open({
                        message: 'Продаем только целыми хлыстами, поэтому ваш заказ будет округлен до целого хлыста.\n',
                        type: 'is-warning',
                        position: 'is-top',
                        actionText: 'Понятно',
                        indefinite: true
                    })
                }
            }
        },
        updated() {
            let h1 = document.getElementsByTagName("h1")[0].textContent;
            if (this.productsArray.some(product => product.name === h1)) {
                this.$store.commit('updateActiveProduct', this.product);
                this.title = h1;
            } else {
                this.$store.commit('cutThePowerInTheBuilding');
                console.log("Товар с таким названием не найден: " + h1);
            }
        },
        mounted() {

        }
    }
</script>

<style scoped>
    .mt20 {
        margin-top: 20px;
    }
    .has-left-separator {
        border-left: 4px solid rgba(0, 0, 0, 0.5);
        background-color: rgba(0, 0, 0, 0.05);
        padding-top: 10px;
    }
    .flexWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 12px 0 12px 0;
    }
    .margin-all > * {
        margin-bottom: 8px;
    }
    .ffflex {
        display: flex;
        align-items: center;
    }
    .ml40 {
        margin-left: 40px;
    }
</style>