import Vue from 'vue'
import App from './App.vue'
import store from './store'
import Buefy from 'buefy'

import './assets/customization.scss'

import VueLocalStorage from 'vue-localstorage'

Vue.use(VueLocalStorage, {
    name: 'localStorage',
    bind: true //created computed members from your variable declarations
})

Vue.use(Buefy)

Vue.filter('rub', function(val) {
    return Number(val).toLocaleString("ru-ru");
})

Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
