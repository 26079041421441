<template>
  <div id="app">
    <h1 v-if="h1show">Труба профильная 40х40х4</h1>
    <component v-bind:is="currentComponent"></component>
    <CartLink></CartLink>
  </div>
</template>

<script>
import Table from './components/Table.vue'
import CartLink from './components/CartLink.vue'
import Cart from './components/Cart.vue'
import SingleProf from './components/single/SingleProf'

export default {
  name: 'app',
  components: {
    Table,
    CartLink,
    Cart,
    SingleProf
  },
  computed: {
    currentComponent() {
      return this.$store.getters.component;
    },
    h1show() {
      if (document.URL === "http://localhost:8080/") {
        return true;
      }
    }
  },
  mounted() {
    //load products list
    this.$store.dispatch('loadCoins');
    //load cart
    if (localStorage.cart !== "[]") {
      let cart = JSON.parse(localStorage.getItem('cart'));
      this.$store.commit('initCart', cart);
    }
    //load component
    if (document.URL === "http://localhost:8080/") {
      this.whoIsLoading("truba-profilnaya");
    } else {
      this.whoIsLoading(document.URL);
    }
  },
  methods: {
    whoIsLoading(url) {
      switch (url) {
        case "https://rdmetall.ru/truba-stalnaya/profilnaya-truba/":
          this.$store.commit('changeComponent', "Table");
          break;
        case "https://rdmetall.ru/cart/":
          this.$store.commit('changeComponent', "Cart");
          break;
        default:
          this.whatSingleComponentLoading(url);
      }
    },
    whatSingleComponentLoading(url) {
      if (url.includes("truba-profilnaya")) {
        this.$store.commit('changeComponent', "SingleProf");
        return;
      }
      this.$store.commit('cutThePowerInTheBuilding');
    }
  }
}
</script>

<style lang="scss">
  #app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 955px;
  margin: 0 auto;
}
  body {
    height: 100%;
  }
</style>
