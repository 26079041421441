<template class="vue-calc-table">
    <section v-if="!hide">
        <b-table :data="productsArray"
                 :striped="true"
                 :hoverable="true"
                 :paginated="true"
                 @click="(obj) => this.onRowClick(obj)"
                 :row-class="(row, index) => 'vueRow'">
            <template slot-scope="props">
                <b-table-column field="name" :searchable="true" label="Продукция">
                    <span v-if="props.row.url !== ' '">
                        {{ props.row.name }} <a class="vue-large-arrow" :href="props.row.url" target="_blank">→</a>
                    </span>
                    <span v-else>
                        {{ props.row.name }}
                    </span>
                </b-table-column>

                <b-table-column field="form" label="Длина, м">
                    {{ props.row.length }}
                </b-table-column>

                <b-table-column field="grade" label="Сталь">
                    {{ props.row.grade }}
                </b-table-column>

                <b-table-column field="cut" label="Цена за рез">
                    {{ props.row.cut }}
                </b-table-column>

                <b-table-column field="price" label="Цена за тонну">
                    {{ props.row.price }}
                </b-table-column>
            </template>
        </b-table>
        <b-modal :active.sync="isComponentModalActive" scroll="keep" aria-role="dialog" aria-modal>
            <modal></modal>
        </b-modal>
    </section>
</template>

<script>
    import modal from './Modal'

    export default {
        localStorage: {
            cart: {
                type: Array,
                default: []
            }
        },
        components: {
            modal
        },
        data() {
            return {
                isComponentModalActive: false,
                selected: '',
            }
        },
        methods: {
            onRowClick(obj) {
                this.$store.commit('updateActiveProduct', obj);
                this.isComponentModalActive = true;
            }
        },
        mounted() {

        },
        computed: {
            productsArray() {
                return Object.keys(this.$store.state.coins).map(key => {
                    return this.$store.state.coins[key];
                });
            },
            hide() {
                return this.$store.getters.globalHide;
            }
        }
    }
</script>

<style lang="scss">
    .vueRow {
        cursor: pointer;
        font-size: 15px;
    }
    .table-wrapper .th-wrap input {
        height: 30px;
    }
    .vueRow-addToCart {
        font-size: 16px;
    }
    .vueRow-addToCart:hover {
        color: #007ea9;
    }
    .vue-large-arrow {
        font-size: 21px;
        display: none;
    }
    .vueRow:hover .vue-large-arrow {
        display: inline-block;
    }
</style>