import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    activeProduct: {},
    coins: {},
    showNotification: true,
    cart:[],
    activeComponent: "SingleProf",
    singleProductTitle: "",
    hideThisShit: false
  },
  mutations: {
    updateActiveProduct(state, payload) {
      state.activeProduct = payload;
    },
    SET_COINS (state, coins) {
      state.coins = coins;
    },
    dontShowNotificationAgain (state) {
      state.showNotification = false;
    },
    initCart(state,payload) {
      state.cart = payload;
    },
    addToCart(state, payload) {
      state.cart.push(payload);
      localStorage.cart = JSON.stringify(state.cart);
    },
    deleteFromCart(state,id) {
      state.cart.splice(state.cart.findIndex(function(i){
        return i.id === id;
      }), 1);
      localStorage.cart = JSON.stringify(state.cart);
    },
    switchToCart(state, payload) {
      state.cartIsActive = payload;
    },
    changeComponent(state, component) {
      state.activeComponent = component;
    },
    //all single mutations
    cutThePowerInTheBuilding(state) {
      state.hideThisShit = true;
    }
  },
  actions: {
    loadCoins ({ commit }) {
      if (document.URL === "http://localhost:8080/") {
        axios
            .get('http://localhost:8080/db.json')
            .then(r => r.data)
            .then(coins => {
              commit('SET_COINS', coins)
            })
      } else {
        axios
            .get('https://rdmetall.ru/catalog/view/calcdb/db.json')
            .then(r => r.data)
            .then(coins => {
              commit('SET_COINS', coins)
            })
      }
    }
  },
  getters: {
    loadedProducts: state => {
      return Object.keys(state.coins).map(key => {
        return state.coins[key];
      });
    },
    name: state => {
      return state.activeProduct.name;
    },
    globalHide: state => {
      return state.hideThisShit;
    },
    length: state => {
      return state.activeProduct.length;
    },
    cutPrice: state => {
      return state.activeProduct.cut;
    },
    price: state => {
      return state.activeProduct.price;
    },
    weight: state => {
      return state.activeProduct.weight;
    },
    notificaion: state => {
      return state.showNotification;
    },
    url: state => {
      return state.activeProduct.url;
    },
    cart: state => {
      return state.cart;
    },
    component: state => {
      return state.activeComponent;
    }
  }
})
