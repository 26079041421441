export default {
    methods: {
        // глобальная функция взаимоотношения трех величин, кол-во штук\кол-во метров\кол-во кг.
        iChange(what, howMuch) {
            if (what === "whip") {

            }
        },
        // Округляем до определенного кол-ва с заданным шагом. Получилось 4, но шаг 6? Тогда результат будет 6. Получилось 21, но шаг 12, будет 24.
        ROUND(number, increment, offset) {
            return Math.ceil((number - offset) / increment) * increment + offset;
        },
    }
}