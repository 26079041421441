<template>
    <section>
        <b-field>
            <b-input icon="account-box" v-model="name" placeholder="ФИО"></b-input>
        </b-field>

        <b-field>
            <b-input type="email"
                     icon="email"
                     v-model="email"
            placeholder="Email">
            </b-input>
        </b-field>

        <b-field>
            <b-input value=""
                     placeholder="Телефон"
                     v-model="phone"
                     icon="cellphone-iphone"
                     type="phone"
            ></b-input>
        </b-field>

        <b-field>
            <b-input type="textarea" v-model="comment" placeholder="Комментарий"></b-input>
        </b-field>
        <div class="field mt20">
            <b-switch v-model="deliveryIsNeeded"
                      type="is-info">
                Требуется доставка
            </b-switch>
        </div>
        <div class="delivery-info" v-if="deliveryIsNeeded">
            <span>Выберите регион доставки:</span>
            <b-field class="mt20">
                <b-radio-button v-model="radioButton"
                                native-value="City"
                                type="is-info">
                    <b-icon icon="city"></b-icon>
                    <span>СПБ</span>
                </b-radio-button>
                <b-radio-button v-model="radioButton"
                                native-value="Oblast"
                                type="is-info">
                    <b-icon icon="home-variant"></b-icon>
                    <span>Лен. область</span>
                </b-radio-button>
            </b-field>
            <b-field>
                <b-input placeholder="Укажите адрес доставки"
                         v-model="deliveryAddress"
                         icon="home"
                expanded>
                </b-input>
            </b-field>
            <b-field
                    class="mt20">
                <b-select placeholder="Укажите адрес доставки" v-model="deliveryCar" expanded icon="truck">
                    <option value="bort">Борт 6м/5тн - 3,500,00₽</option>
                    <option value="shalanda">Шаланда 12м/20тн - 5,500,00₽</option>
                </b-select>
            </b-field>
        </div>
        <div class="mt20">
            <b-button @click="sendEmail" :loading="buttonIsLoading" size="is-large" expanded type="is-info">Отправить</b-button>
        </div>
        <div id="konturHiddenTable">
            <table border="1" cellpadding="7" text-align="center" style="text-align: center">
                <thead>
                <tr>
                    <td>Наименование товара</td>
                    <td>Кол-во</td>
                    <td>Резка</td>
                    <td>Итого</td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item,id) in cartArray" :key="item.id">
                    <td>{{item.name}}</td>
                    <td>{{item.meter}} пог.м = {{item.kgOrdered}} кг ({{item.whip}} шт)</td>
                    <td v-if="item.cutTimes > 0">{{item.cutTimes}} реза(ов) по {{item.cut}} м - {{item.cutsPrice | rub}} ₽</td>
                    <td v-else>---</td>
                    <td>{{item.finalPrice | rub}} ₽</td>
                </tr>
                <tr>
                    <td colspan="4">Итого: {{cartLength}} на <strong>{{allProductsTotalPrice | rub}} ₽</strong> и весом {{totalKgOrdered}} кг</td>
                </tr>
                </tbody>
            </table>
        </div>
    </section>
</template>

<script>
    var Email = { send: function (a) { return new Promise(function (n, e) { a.nocache = Math.floor(1e6 * Math.random() + 1), a.Action = "Send"; var t = JSON.stringify(a); Email.ajaxPost("https://smtpjs.com/v3/smtpjs.aspx?", t, function (e) { n(e) }) }) }, ajaxPost: function (e, n, t) { var a = Email.createCORSRequest("POST", e); a.setRequestHeader("Content-type", "application/x-www-form-urlencoded"), a.onload = function () { var e = a.responseText; null != t && t(e) }, a.send(n) }, ajax: function (e, n) { var t = Email.createCORSRequest("GET", e); t.onload = function () { var e = t.responseText; null != n && n(e) }, t.send() }, createCORSRequest: function (e, n) { var t = new XMLHttpRequest; return "withCredentials" in t ? t.open(e, n, !0) : "undefined" != typeof XDomainRequest ? (t = new XDomainRequest).open(e, n) : t = null, t } };
    export default {
        name: "CartForm",
        data() {
            return {
                name: "",
                phone: "",
                email: "",
                comment: "",
                deliveryIsNeeded: false,
                radioButton: "City",
                deliveryCar: "bort",
                deliveryAddress: "",
                buttonIsLoading: false
            }
        },
        methods: {
            conjugate(quantity, words) {
                var sec_opt = [2, 3, 4];
                var ten_cond = !(quantity > 10 && quantity < 15);
                var unit_val = (quantity % 10 === 1 && ten_cond)
                    ? words[0]
                    : ((sec_opt.indexOf(quantity % 10) > -1 && ten_cond) ? words[1] : words[2]);
                return unit_val;
            },
            sendEmail() {
                let table = document.getElementById("konturHiddenTable").innerHTML;
                this.buttonIsLoading = true;
                Email.send({
                    Host: "smtp.elasticemail.com",
                    Username: "dark33power@gmail.com",
                    Password: "51642d13-24ab-40a9-8fe0-f6a7a7a9c329",
                    To: 'od@rdmetall.ru',
                    From: "dark33power@gmail.com",
                    Subject: "Новая заявка от калькулятора на сайте.",
                    Body: `<h1 style="color: #1c4f82dc">Новая заявка</h1>
                        <h2 style="color: #2e6c80;">ФИО: ${this.name}</h2>
                        <h2 style="color: #2e6c80;">Телефон: ${this.phone}</h2>
                        <h2 style="color: #2e6c80;">Email: ${this.email}</h2>
                        <h2 style="color: #2e6c80;">Комментарий к заказу: ${this.comment}</h2>
                        <h2 style="color: #2e6c80;">Доставка: ${this.deliverySum}</h2>
                        <h2 style="color: #2e6c80;">Заказ:</h2>`+ table
                }).then(
                    message => this.emailIsSend()
                );
            },
            emailIsSend() {
                this.buttonIsLoading = false;
                this.$buefy.snackbar.open({
                    message: 'Мы получили и приняли Ваш заказ в работу. Менеджер перезвонит в течении 15 минут.\n',
                    type: 'is-success',
                    position: 'is-top',
                    actionText: 'Спасибо, жду',
                    indefinite: true
                })
            }
        },
        computed: {
            cartArray() {
                return this.$store.getters.cart;
            },
            allProductsTotalPrice() {
                let arr = this.cartArray;
                let result = arr.reduce(function(sum, current) {
                    return sum + Number(current.finalPrice);
                }, 0);
                return Number(result);
            },
            cartLength() {
                return this.cartArray.length + ' ' + this.conjugate(this.cartArray.length, ['товар','товара','товаров'])
            },
            totalKgOrdered() {
                let arr = this.cartArray;
                let result = arr.reduce(function(sum, current) {
                    return sum + Number(current.kgOrdered);
                }, 0);
                return Number(result).toFixed(2);
            },
            deliverySum() {
                let region = this.radioButton === "City" ? "по городу" : "по Лен. области";
                let address = this.deliveryAddress;
                let car = this.deliveryCar === "bort" ? " бортовой машиной 6м/5тн - 3,500,00 ₽" : " шаландой 12м/20тн - 5,500,00 ₽";
                if (this.deliveryIsNeeded) {
                    return "Нужна доставка " + region + " на адрес " + address + car;
                } else {
                    return "Приеду, заберу из офиса на Шостаковича."
                }
            }
        }
    }
</script>

<style scoped>
    .mt20 {
        margin-top: 10px;
    }
    .vue-cart-form {
        box-shadow: 0px 0px 4px 0px rgba(204,204,204,1);
    }
    #konturHiddenTable {
        display: none;
    }
    .delivery-info {

    }
</style>